import React from "react";
import SimpleLayout from "../components/SimpleLayout";

const AgbPage = () => (
	<SimpleLayout>
		<h1>Allgemeine Geschäftsbedingungen</h1>
		<strong>1. Geltungsbereich</strong>
		<p>
			Für alle Bestellungen über unseren Online-Shop durch Verbraucher und Unternehmer gelten
			die nachfolgenden AGB.
			<br />
			<br />
			Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt,
			die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit
			zugerechnet werden können. Unternehmer ist eine natürliche oder juristische Person oder
			eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in
			Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.
			<br />
			<br />
			Gegenüber Unternehmern gelten diese AGB auch für künftige Geschäftsbeziehungen, ohne
			dass wir nochmals auf sie hinweisen müssten. Verwendet der Unternehmer entgegenstehende
			oder ergänzende Allgemeine Geschäftsbedingungen, wird deren Geltung hiermit
			widersprochen; sie werden nur dann Vertragsbestandteil, wenn wir dem ausdrücklich
			zugestimmt haben.
		</p>
		<strong>2. Vertragspartner, Vertragsschluss</strong>
		<p>Der Kaufvertrag kommt zustande mit Lokodin GmbH.</p>
		<p>
			Die Darstellung der Produkte im Online-Shop stellt kein rechtlich bindendes Angebot,
			sondern einen unverbindlichen Online-Katalog dar. Sie können unsere Produkte zunächst
			unverbindlich in den Warenkorb legen und Ihre Eingaben vor Absenden Ihrer verbindlichen
			Bestellung jederzeit korrigieren, indem Sie die hierfür im Bestellablauf vorgesehenen
			und erläuterten Korrekturhilfen nutzen. Durch Anklicken des Bestellbuttons geben Sie
			eine verbindliche Bestellung der im Warenkorb enthaltenen Waren ab. Die Bestätigung des
			Zugangs Ihrer Bestellung erfolgt per E-Mail unmittelbar nach dem Absenden der
			Bestellung.
			<br />
			<br />
			Wann der Vertrag mit uns zustande kommt, richtet sich nach der von Ihnen gewählten
			Zahlungsart:
		</p>{" "}
		<p>
			<strong>Vorkasse</strong>
			<br /> Wir nehmen Ihre Bestellung durch Versand einer Annahmeerklärung in separater
			E-Mail innerhalb von zwei Tagen an, in welcher wir Ihnen unsere Bankverbindung nennen.{" "}
		</p>{" "}
		<strong>3. Vertragssprache, Vertragstextspeicherung</strong>
		<br />
		Die für den Vertragsschluss zur Verfügung stehenden Sprachen sind Deutsch und Englisch.{" "}
		<br />
		<p>Der Vertragstext wird von uns nicht gespeichert.</p>{" "}
		<strong>4. Lieferbedingungen</strong>
		<br />
		<p>
			Zuzüglich zu den angegebenen Produktpreisen können noch Versandkosten anfallen. Nähere
			Bestimmungen zu ggf. anfallenden Versandkosten erfahren Sie bei den Angeboten.
		</p>
		<p>
			{" "}
			Wir liefern nur im Versandweg. Eine Selbstabholung der Ware ist leider nicht möglich.
		</p>
		<p> Wir liefern nicht an Packstationen.</p>
		<p />
		<strong>5. Bezahlung</strong>{" "}
		<p>In unserem Shop stehen Ihnen grundsätzlich die folgenden Zahlungsarten zur Verfügung:</p>{" "}
		<p>
			<strong>Vorkasse</strong>
			<br /> Bei Auswahl der Zahlungsart Vorkasse nennen wir Ihnen unsere Bankverbindung in
			separater E-Mail und liefern die Ware nach Zahlungseingang.
		</p>{" "}
		<strong>6. Eigentumsvorbehalt</strong>
		<br />{" "}
		<p>
			Die Ware bleibt bis zur vollständigen Bezahlung unser Eigentum.
			<br />
			Für Unternehmer gilt ergänzend: Wir behalten uns das Eigentum an der Ware bis zur
			vollständigen Begleichung aller Forderungen aus einer laufenden Geschäftsbeziehung vor.
			Sie dürfen die Vorbehaltsware im ordentlichen Geschäftsbetrieb weiterveräußern;
			sämtliche aus diesem Weiterverkauf entstehenden Forderungen treten Sie – unabhängig von
			einer Verbindung oder Vermischung der Vorbehaltsware mit einer neuen Sache - in Höhe des
			Rechnungsbetrages an uns im Voraus ab, und wir nehmen diese Abtretung an. Sie bleiben
			zur Einziehung der Forderungen ermächtigt, wir dürfen Forderungen jedoch auch selbst
			einziehen, soweit Sie Ihren Zahlungsverpflichtungen nicht nachkommen.
		</p>{" "}
		<strong>7. Transportschäden</strong>
		<br />{" "}
		<p>
			{" "}
			Für Verbraucher gilt: <br /> Werden Waren mit offensichtlichen Transportschäden
			angeliefert, so reklamieren Sie solche Fehler bitte möglichst sofort beim Zusteller und
			nehmen Sie bitte unverzüglich Kontakt zu uns auf. Die Versäumung einer Reklamation oder
			Kontaktaufnahme hat für Ihre gesetzlichen Ansprüche und deren Durchsetzung, insbesondere
			Ihre Gewährleistungsrechte, keinerlei Konsequenzen. Sie helfen uns aber, unsere eigenen
			Ansprüche gegenüber dem Frachtführer bzw. der Transportversicherung geltend machen zu
			können.
		</p>{" "}
		<p>
			{" "}
			Für Unternehmer gilt:
			<br /> Die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung geht auf
			Sie über, sobald wir die Sache dem Spediteur, dem Frachtführer oder der sonst zur
			Ausführung der Versendung bestimmten Person oder Anstalt ausgeliefert haben. Unter
			Kaufleuten gilt die in § 377 HGB geregelte Untersuchungs- und Rügepflicht. Unterlassen
			Sie die dort geregelte Anzeige, so gilt die Ware als genehmigt, es sei denn, dass es
			sich um einen Mangel handelt, der bei der Untersuchung nicht erkennbar war. Dies gilt
			nicht, falls wir einen Mangel arglistig verschwiegen haben.
		</p>
		<strong>8. Gewährleistung und Garantien</strong>
		<br />{" "}
		<p>
			Soweit nicht nachstehend ausdrücklich anders vereinbart, gilt das gesetzliche
			Mängelhaftungsrecht.
			<br /> Beim Kauf gebrauchter Waren durch Verbraucher gilt: wenn der Mangel nach Ablauf
			eines Jahres ab Ablieferung der Ware auftritt, sind die Mängelansprüche ausgeschlossen.
			Mängel, die innerhalb eines Jahres ab Ablieferung der Ware auftreten, können im Rahmen
			der gesetzlichen Verjährungsfrist von zwei Jahren ab Ablieferung der Ware geltend
			gemacht werden.
			<br /> Für Unternehmer beträgt die Verjährungsfrist für Mängelansprüche bei neu
			hergestellten Sachen ein Jahr ab Gefahrübergang. Der Verkauf gebrauchter Waren erfolgt
			unter Ausschluss jeglicher Gewährleistung. Die gesetzlichen Verjährungsfristen für den
			Rückgriffsanspruch nach § 445a BGB bleiben unberührt.
			<br /> Gegenüber Unternehmern gelten als Vereinbarung über die Beschaffenheit der Ware
			nur unsere eigenen Angaben und die Produktbeschreibungen des Herstellers, die in den
			Vertrag einbezogen wurden; für öffentliche Äußerungen des Herstellers oder sonstige
			Werbeaussagen übernehmen wir keine Haftung.
			<br /> Ist die gelieferte Sache mangelhaft, leisten wir gegenüber Unternehmern zunächst
			nach unserer Wahl Gewähr durch Beseitigung des Mangels (Nachbesserung) oder durch
			Lieferung einer mangelfreien Sache (Ersatzlieferung).
			<br /> Die vorstehenden Einschränkungen und Fristverkürzungen gelten nicht für Ansprüche
			aufgrund von Schäden, die durch uns, unsere gesetzlichen Vertreter oder
			Erfüllungsgehilfen verursacht wurden
		</p>{" "}
		<ul>
			{" "}
			<li>bei Verletzung des Lebens, des Körpers oder der Gesundheit</li>{" "}
			<li>bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung sowie Arglist</li>{" "}
			<li>
				bei Verletzung wesentlicher Vertragspflichten, deren Erfüllung die ordnungsgemäße
				Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der
				Vertragspartner regelmäßig vertrauen darf (Kardinalpflichten){" "}
			</li>{" "}
			<li>im Rahmen eines Garantieversprechens, soweit vereinbart</li>{" "}
			<li>soweit der Anwendungsbereich des Produkthaftungsgesetzes eröffnet ist.</li>{" "}
		</ul>{" "}
		<p>
			Informationen zu gegebenenfalls geltenden zusätzlichen Garantien und deren genaue
			Bedingungen finden Sie jeweils beim Produkt und auf besonderen Informationsseiten im
			Onlineshop.
		</p>{" "}
		<strong>9. Haftung</strong>
		<br />{" "}
		<p>
			Für Ansprüche aufgrund von Schäden, die durch uns, unsere gesetzlichen Vertreter oder
			Erfüllungsgehilfen verursacht wurden, haften wir stets unbeschränkt
		</p>{" "}
		<ul>
			{" "}
			<li> bei Verletzung des Lebens, des Körpers oder der Gesundheit,</li>{" "}
			<li> bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung,</li>{" "}
			<li> bei Garantieversprechen, soweit vereinbart, oder</li>{" "}
			<li> soweit der Anwendungsbereich des Produkthaftungsgesetzes eröffnet ist.</li>{" "}
		</ul>{" "}
		<p>
			Bei Verletzung wesentlicher Vertragspflichten, deren Erfüllung die ordnungsgemäße
			Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der
			Vertragspartner regelmäßig vertrauen darf, (Kardinalpflichten) durch leichte
			Fahrlässigkeit von uns, unseren gesetzlichen Vertretern oder Erfüllungsgehilfen ist die
			Haftung der Höhe nach auf den bei Vertragsschluss vorhersehbaren Schaden begrenzt, mit
			dessen Entstehung typischerweise gerechnet werden muss. Im Übrigen sind Ansprüche auf
			Schadensersatz ausgeschlossen.
		</p>{" "}
		<p>
			{" "}
			<strong>10. Streitbeilegung</strong>
			<br /> Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
			bereit, die Sie hier finden{" "}
			<a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="nofollow">
				https://ec.europa.eu/consumers/odr/
			</a>
			. <br /> Wir sind bereit, an einem außergerichtlichen Schlichtungsverfahren vor einer
			Verbraucherschlichtungsstelle teilzunehmen. <br /> Zuständig ist die Allgemeine
			Verbraucherschlichtungsstelle des Zentrums für Schlichtung e.V., Straßburger Straße 8,
			77694 Kehl am Rhein,{" "}
			<a href="https://www.verbraucher-schlichter.de" target="_blank" rel="nofollow">
				www.verbraucher-schlichter.de
			</a>
			.{" "}
		</p>{" "}
		<div>
			{" "}
			<strong>11. Schlussbestimmungen</strong>
			<br />{" "}
			<p>
				Sind Sie Unternehmer, dann gilt deutsches Recht unter Ausschluss des UN-Kaufrechts.
			</p>{" "}
			<p>
				Sind Sie Kaufmann im Sinne des Handelsgesetzbuches, juristische Person des
				öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, ist ausschließlicher
				Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen zwischen uns und
				Ihnen unser Geschäftssitz.
			</p>{" "}
		</div>
	</SimpleLayout>
);

export default AgbPage;
